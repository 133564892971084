@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Cubano";
	src: url(./assets/fonts/Cubano.ttf);
}

body {
	margin: 0;
	font-family: "Cubano", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

body > div:last-child > img {
	display: inline !important;
}
body * {
	@apply transition-colors duration-300;
}
